.loaderContainer {
    border-radius: 11px;
    backdrop-filter: saturate(180%) blur(3px);
    background-color: #ffffff94;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #17396d;
    font-weight: bold;
}
