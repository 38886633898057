.searchContainer {
  margin: 0 0 35px 0;

  .inputGroup {
    display: flex;
    align-items: center;

    .input {
      border-radius: 0.357rem 0 0 0.357rem;
    }

    .searchButton {
      flex: 8%;
      border-radius: 0;
      padding: 10px 0px !important;
      justify-content: center;

      span {
        display: flex;
        align-items: center;
      }
    }

    .advanceSearchBtn {
      flex: 1%;
      border-radius: 0 6px 6px 0;
      padding: 9px 12px;

      span {
        svg {
          transition: 350ms all;
          font-size: 14px;
        }
      }
    }
  }

  .advanceSearch {
    box-shadow: 0px 7px 13px 0px #0000001a;
    padding: 20px;
    border-radius: 10px;

    .action {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }
  }
}
