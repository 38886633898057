.datePicker {
  label {
    font-size: 15px;
    flex-basis: 45%;
  }
  .calendarIcon {
    right: 12px;
    top: 53%;
    font-size: 20px;

    svg {
      color: rgb(92, 92, 92);
    }
  }
}
