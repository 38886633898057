.validation-error {
    outline: solid;
    outline-width: 1px;
    outline-color: #DC3545;
    border-radius: 4px;

    &:focus,
    &:active {
        border-color: #DC3545 !important;
        outline: 0;
        box-shadow: 0 0 0 0.2rem #dc354636 !important;
    }
}