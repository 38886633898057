.navbar {
    background-color: #17396d;
    color: #fff;
    padding: 15px;
    position: fixed;
    width: 100%;
    z-index: 15;
}

.menuItem {
    position: relative;

    a {
        position: relative;
        line-height: 0;

        svg {
            font-size: 22px;
        }

        &:hover {
            cursor: pointer;
        }

        .notificationCount {
            position: absolute;
            top: -8px;
            right: -10px;
            width: -moz-fit-content;
            width: fit-content;
            height: 18px;
            background-color: #dc3545;
            color: #fff;
            border-radius: 22px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 11px;
            padding: 0 6px;
        }
    }
}

.mobileSideBar {
    position: relative;
    min-width: 300px;
    color: #dee8ff;

    .closeIcon {
        position: absolute;
        right: 10px;
        font-size: 30px;
        top: 7px;
    }
}

.profileContainer {
    display: flex;
    background-color: #17396d;
    height: 150px;
    padding: 15px 5px;

    div {
        margin-top: auto;

        p {
            color: #fff;
        }

        .avatar {
            width: 50px;
            height: 50px;
            background-color: rgb(255, 255, 255);
            color: rgb(26, 26, 26);
            border: 3px solid #d9d9d9;
            border-radius: 50%;
            margin: 0 10px;
        }
    }
}

.active {
    color: #1976d2;
    padding-left: 10px;
    background-color: rgba(25, 118, 210, 0.08);
}
