@import "./font-family";
@import "./utils";

:root {
  --theme-deafult: #17396d;
}

@import "./components";
@import "./theme";
@import "./utils/scrollbar";

table {
  display: table;
  border-collapse: separate;
  box-sizing: border-box;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

.action-1x {
  padding: 0 35px;
}

.border-danger {
  border: 2px solid #dc3545;
}

.border-success {
  border: 2px solid #198754;
}

.error-message {
  color: #dc3545;
  font-size: 12px !important;
}

.sideNaveBarWrapper {
  background-color: rgba(59, 61, 74, 0.2);
  height: 100vh;
  width: 100%;
  z-index: 150;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  transition: 350ms all ease;

  .sideNav {
    width: 80%;
    height: 100vh;
    background: #fff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
    padding: 35px;
  }

  .sideNav.active {
    transform: translateX(0);
  }
}

.sideNaveBarWrapper.active {
  opacity: 1;
  pointer-events: all;
}

.right-px-1 {
  right: -100%;
}

.layout-min-h {
  min-height: calc(100vh - 375px);
}

.divider {
  width: 100%;
  border: 1px dashed #36393d50;
}
