$breakpoint-sm: 576px; //mobile devices
$breakpoint-md: 768px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1280px;

$primary-color: #17396D;
$success-color: #198754;
$danger-color:#DC3545;
$warning-color:#FFC107;
$transparent-color: transparent;
$white:#fff