.inputWrapper {
  margin: 14px 0;
  position: relative;

  label {
    font-size: 15px;
  }

  .label {
    flex-basis: 45%;
  }

  .inputFieldContainer {
    width: 100%;
  }
  .inputField {
    display: block;
    width: 100%;
    padding: 0.5rem 0.65rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.45;
    color: #343338;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem #007bff40;
    }

    &:focus-visible {
      outline: #cfd9e0;
    }

    &:disabled {
      background-color: #f7f7f7;
    }
  }

  .error {
    display: flex;
    align-items: center;
    position: absolute;
    right: 12px;
    bottom: 12px;
    font-size: 12px;

    svg {
      margin-right: 5px;
      margin-bottom: 2px;
    }
  }

  .bottomElement {
    position: absolute;
    bottom: -65%;
  }
  .innerElement {
    position: absolute;
    bottom: 25%;
    right: 12px;

    label {
      color: #343338;
      font-size: 12px;
      display: flex;
      align-items: center;
      margin: 0;

      svg {
        margin-right: 5px;
      }
    }
  }
}
