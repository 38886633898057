@font-face {
    font-family: 'NotoSans';
    src: local('NotoSans'), url(../fonts/Noto_Sans/NotoSans-Light.ttf) format('truetype');
    font-weight: 300;
    direction: rtl;
}


@font-face {
    font-family: 'NotoSans';
    src: local('NotoSans'), url(../fonts/Noto_Sans/NotoSans-Regular.ttf) format('truetype');
    font-weight: 400;
    direction: rtl;
}

@font-face {
    font-family: 'NotoSans-SemiBold';
    src: local('NotoSans'), url(../fonts/Noto_Sans/NotoSans-SemiBold.ttf) format('truetype');
    font-weight: 600;
    direction: rtl;
}

@font-face {
    font-family: 'NotoSans';
    src: local('NotoSans'), url(../fonts/Noto_Sans/NotoSans-Bold.ttf) format('truetype');
    font-weight: 700;
    direction: rtl;
}

@font-face {
    font-family: 'NotoSans';
    src: local('NotoSans'), url(../fonts/Noto_Sans/NotoSans-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    direction: rtl;
}