.callsContainer {
    padding: 10px 20px;
    min-width: 1000px;

    h3 {
        display: flex;
        align-items: center;

        svg {
            margin-right: 5px;
        }
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .closeBtn {
            line-height: 0;
            font-size: 24px;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.causeCode {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
    bottom: -1px;
    font-size: 9px;
    color: #000;
}
