.menuBar {
  margin-top: 64px;

  a,
  .menuItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    color: #2b2b2b;
    font-weight: 400;
    line-height: 23px;
    padding: 8px 10px;
    font-size: 15px;
    text-decoration: none;
    text-transform: capitalize;
    transition: all 0.3s ease;
    gap: 6px;

    svg {
      &:last-child {
        font-size: 12px;
        margin-top: 2px;
      }
    }

    &:hover {
      color: #1976d2;
      background-color: rgba(25, 118, 210, 0.08);

      svg {
        path {
          stroke: #1976d2;
        }
      }
    }
  }

  .subItem {
    position: absolute;
    background-color: #fff;
    min-width: 225px;
    box-shadow: 0px 4px 9px 0px #bdbdbd;
    top: 45px;

    .active {
      color: #1976d2 !important;
      background-color: transparent !important;
    }

    .subMenu {
      position: absolute;
      right: -65%;
      top: 0;
      background: white;
      min-width: 150px;
      box-shadow: 0px 4px 9px 0px #bdbdbd;
      border-radius: 4px;
    }
  }

  .active {
    color: #1976d2;
    background-color: rgba(25, 118, 210, 0.08);
  }
}
