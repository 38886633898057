.form-control {
    display: block;
    width: 100%;
    padding: 0.53rem 0.65rem;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.45;
    color: #6e6b7b;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    ::placeholder {
        font-size: 12px;
    }

    &:focus {
        color: #495057;
        background-color: #fff;
        border-color: #80bdff;
        outline: 0;
        box-shadow: 0 0 0 0.2rem #007bff40;
    }

    &:focus-visible {
        outline: #CFD9E0;

    }
}

.react-datepicker-wrapper {
    margin-top: 0.5rem;
}

label {
    margin-bottom: 0.5rem;
    font-size: 14px;
}