.selectContainer {
  margin: 14px 0;
  position: relative;

  label {
    margin-bottom: 0;
    font-size: 15px;
    flex-basis: 44%;
  }
}

.select {
  margin-top: 0.5rem;
  width: 100%;
}

.bottomElement {
  width: 100%;
  position: absolute;
  bottom: -30%;
}
