.executionCardContainer {
  position: relative;
  background: #f7f7f7;
  box-shadow: 1px 10px 12px 2px #e7e7e7;
  border-radius: 11px;
  min-height: 200px;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 18px;

  .title {
    width: fit-content;

    div {
      margin-top: 5px;
      align-items: center;

      .status {
        padding: 2px 10px;
        border-radius: 40px;
        font-size: 10px;
      }

      :first-child {
        font-size: 14px;
      }
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .propertyCard {
      position: relative;
      width: 100px;
      min-height: 40px;
      border-radius: 8px;
      display: flex;
      padding: 10px 10px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-right: 5px;

      p {
        color: #fff;
        font-size: 14px;

        &:last-child {
          font-size: 11px;
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .duration {
      display: flex;
      align-items: center;
      font-size: 14px;
      img {
        margin-right: 5px;
      }
    }
  }
}

.ShadowSuccess {
  box-shadow: 1px 10px 12px 2px #198754;
}

.ShadowDanger {
  box-shadow: 1px 10px 12px 2px #dc354575;
}

.gauge {
  position: relative;
  border-radius: 50%/100% 100% 0 0;
  background-color: #2f835e;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-top: 50%;
    /* ratio of 2:1*/
  }

  .chart {
    overflow: hidden;
  }

  .mask {
    position: absolute;
    left: 10%;
    right: 10%;
    bottom: 0;
    top: 20%;
    background-color: #f7f7f7;
    border-radius: 50%/100% 100% 0 0;
  }

  .percentage {
    position: absolute;
    transition: 350ms all ease;
    top: -1px;
    left: -1px;
    bottom: 0;
    right: -1px;
    background-color: #e6ebf8;

    transform-origin: bottom center;
    transition-duration: 600;
  }

  .value {
    position: absolute;
    bottom: 30%;
    left: 0;
    width: 100%;
    text-align: center;
  }

  .currentCalls {
    position: absolute;
    font-size: 12px;
    bottom: -5%;
    left: 0;
    font-weight: bold;
    width: 100%;
    text-align: center;
  }

  .min {
    position: absolute;
    bottom: 0;
    left: 5%;
  }

  .max {
    position: absolute;
    bottom: 0;
    right: 5%;
  }
}

.executionAction {
  display: flex;
  align-items: center;
  min-width: 90px;
  height: 30px;
  border-radius: 50px;
  padding: 15px 5px;
  color: #fff;
  justify-content: space-between;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  .label {
    background-color: #f7f7f7;
    border-radius: 50px;
    font-size: 13px;
    padding: 0 10px;
    color: #000;
  }

  .icon {
    font-size: 20px;
    line-height: 0 !important;
  }
}

.callsCount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 4px 8px;
  background: #e2e2e2;
  border-radius: 8px;
  font-size: 14px;
  color: rgb(37, 37, 37);

  svg {
    margin-right: 6px;
  }
}
