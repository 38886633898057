.modalHead {
    position: relative;
    padding: 20px 30px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.08);
}

.modalBody {
    padding: 12px 30px 12px 30px;
}

.modalFoot {
    padding: 20px 15px;
}

.title {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #000000;
}

.close {
    position: absolute;
    top: -29px;
    right: -5px;
    padding: 6px;
    color: #fd3309;
    display: flex;
    margin-left: auto;

    span {
        font-size: 24px;
        color: #fff;
    }
}