@import "../../assets/scss/utils/mixins";

.table {
  width: 100%;
  margin-top: 22px;

  @include mobile() {
    width: max-content;
  }

  .tableHead {
    tr {
      text-align: left;
      line-height: 35px;
      height: 50px;
    }

    th {
      color: #322f2f;
      border-bottom: 0;

      &:first-child {
        padding-left: 33px;
        border-radius: 7px 0 0 7px;
      }

      &:last-child {
        border-radius: 0 7px 7px 0;
      }

      -webkit-touch-callout: none;
      /* iOS Safari */
      -webkit-user-select: none;
      /* Safari */
      -khtml-user-select: none;
      /* Konqueror HTML */
      -moz-user-select: none;
      /* Old versions of Firefox */
      -ms-user-select: none;
      /* Internet Explorer/Edge */
      user-select: none;

      &:hover {
        cursor: pointer;
      }
    }

    background: #f5f5f6;
    height: 52px;
  }

  .tableBody {
    tr {
      padding-left: 33px;
      line-height: 40px;
      height: 55px;
      border-bottom: 1px solid #e6e6e6;

      &:hover {
        background: #f2f2f2 !important;
      }

      td {
        font-size: 14px;

        &:first-child {
          padding-left: 33px;
        }

        a {
          font-family: "NotoSans-SemiBold";
        }
      }
    }
  }
}
