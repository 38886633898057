.badge {
    /* 12px */
    font-size: 0.65rem;
    /* 16px */
    line-height: 1rem;
    font-weight: 600;
    /* 10px */
    padding-left: 0.625rem;
    /* 10px */
    padding-right: 0.625rem;
    /* 4px */
    border-radius: 0.25rem;
    /* 2px */
    padding-top: 3px;
    padding-bottom: 5px;
}

.badge-primary {
    background-color: #17396d;
    color: #e0ebf8;
}

.badge-dark {
    background-color: #34354b;
    color: #f3f4f6;
}

.badge-warning {
    background-color: #efd043;
    color: #34354b;
}

.badge-purple {
    background-color: #7b1fa2;
    color: #ecd1f3;
}

.badge-success {
    background-color: #02756a;
    color: #e7fffd;
}

.badge-danger {
    background-color: #e30a03;
    color: #fde5e5;
}

.badge-info {
    background-color: #17a2b8;
    color: #fff;
}

.badge-light {
    background-color: #e0e0e0;
    color: rgb(43, 39, 39);
}

.badge-olive {
    background-color: #8bc34a;
    color: white;
}

.badge-dark-teal {
    background-color: #16a085;
    color: white;
}

.badge-dark-gray {
    background-color: #34495e;
    color: white;
}
