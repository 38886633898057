@import '../../assets/scss/utils/variable';

.fileUploadContainer {
    width: 100%;
    min-height: 180px;
    border: 0.93px dashed #d3d3d3;
    box-sizing: border-box;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;

    .uploadIcon {
        span {
            color: $primary-color;
            font-size: 46px;
        }
    }

    p {
        font-size: 14px;
        text-align: center;
    }

    .browse {
        display: inline;

        label {
            color: #1589fd;
            font-weight: bold;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .requirementsLabel {
        font-size: 12px;
        color: rgb(105, 105, 105);
    }
}

.error {
    border: 0.93px dashed #ff1212;
}

.selectedFileContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 10px;
    background-color: #dadada;
    border-radius: 9px;

    span {
        &:hover {
            cursor: pointer;
        }
    }
}