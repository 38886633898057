.notificationContainer {
    position: absolute;
    width: 360px;
    height: 350px;
    max-height: 350px;
    overflow: hidden;
    background-color: #fff;
    z-index: 12;
    color: rgb(69, 69, 69);
    box-shadow: 2px 18px 27px -12px rgba(150, 150, 150, 1);

    right: -160px;
    top: 43px;
    border-radius: 8px;

    .icon {
        position: absolute;
        left: 50%;
        top: -20px;
    }

    .title {
        padding: 20px 20px;
        background-color: #eaeaea;
        border-bottom: 1px solid rgb(229, 229, 229);
        position: relative;

        .notificationIcon {
            color: #000;
            position: absolute;
            width: 50px;
            height: 50px;
            top: 0;
            right: 0;
            opacity: 0.05;
            font-size: 46px;
        }
    }

    .noNotificationContainer {
        display: flex;
        padding: 0 30px;
        flex-direction: column;
        height: calc(100% - 65px);
        justify-content: center;
        align-items: center;

        p {
            text-align: center;
            font-size: 12px;
            margin-top: 8px;
        }
    }
}

.notificationItem {
    display: flex;
    padding: 10px 12px;
    justify-content: space-between;
    align-items: center;
}
