.profileDropdownWrapper {
    display: flex;

    &:hover {
        cursor: pointer;
    }

    .avatar {
        width: 44px;
        height: 44px;
        background-color: #000;
        color: white;
        border: 3px solid #D9D9D9;
        border-radius: 8px;
        margin: 0 10px;
    }

    p {
        color: #fff;

        img {
            margin-left: 5px;
            transition: 350ms all ease;
        }
    }
}

.popOver {
    top: 15px !important;

}


.dropdown {
    background: #fff;
    width: 180px;
    border-radius: 4px;

    box-shadow: 0 4px 12px rgba(#000, .1);
    text-align: left;
    transition: 0.3s ease;

    li {
        background: #fff;
        padding: 0 20px;
        color: #000;
        list-style: none;
        transition: 350ms all ease;

        &:hover {
            background-color: #ededed;
        }

        &.active {
            color: blue;
        }

        &:first-child {
            border-radius: 4px 4px 0 0;
        }

        &:last-child {
            border-radius: 0 0 4px 4px;

            a {
                border-bottom: 0;
            }
        }
    }

    a {
        display: flex;
        align-items: center;
        border-bottom: 1px solid rgba(#000, .05);
        padding: 12px 0;
        color: #000;
        font-size: 13px;
        text-decoration: none;

        &:hover {
            cursor: pointer;
        }

        svg {
            margin-right: 5px;
        }
    }
}