$opacity: 100%;
$opacity__: 80%;

.tilesWrapper {
    color: white;
    border-radius: 8px;
    padding: 18px 10px;

    @for $i from 2 through 4 {
        $opacity: $opacity - 5%;

        &:nth-child(#{$i}) {
            opacity: $opacity;
        }
    }

    @for $n from 5 through 8 {
        $opacity__: $opacity__ + 5%;

        &:nth-child(#{$n}) {
            opacity: $opacity__;
        }
    }

    h1 {
        font-size: 18px;
        font-weight: 400;
    }

    p {
        font-size: 11px;
        font-weight: normal;
    }

    h4 {
        font-size: 18px;
    }
}