.sliderWrapper {
    margin: 18px 0;

    label {
        font-size: 14px;
    }

}

.checkboxContainer {
    label {
        padding: 0;
        margin: 0;

        span {
            padding: 0 5px;
            color: rgb(118, 118, 118);
            font-size: 14px;
        }
    }
}