@import "../../assets/scss/utils/variable";

.button {
  border: 0;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  color: $white;
  transition: 350ms all ease;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    background-color: rgba(23, 57, 109, 0.75);
    cursor: not-allowed;
  }
}

.link {
  background-color: transparent !important;
  border: none;
  min-width: auto;
  height: auto;
  text-decoration: none;
  padding: 0px !important;
  text-align: left;
  cursor: pointer;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  transition: 350ms all ease;

  :disabled {
    color: red !important;
    background-color: red;
  }

  &:hover {
    text-decoration: underline;
  }

  span {
    color: $primary-color;
    font-size: 16px;
  }
}

.Primary {
  background-color: $primary-color;

  &:hover:not([disabled]) {
    background-color: rgba(23, 57, 109, 0.9);
  }
}

.PrimaryOutline {
  background-color: transparent;
  border: 1px solid $primary-color;

  span {
    color: $primary-color !important;
  }

  &:hover {
    span {
      color: #ffffff !important;
    }
  }

  &:hover:not([disabled]) {
    background-color: $primary-color;
  }
}

.DangerOutline {
  background-color: transparent;
  border: 1px solid $danger-color;

  span {
    color: $danger-color !important;
  }

  &:hover {
    span {
      color: #ffffff !important;
    }
  }

  &:hover:not([disabled]) {
    background-color: $danger-color;
  }
}

.Secondary {
  color: $primary-color;
  background-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 0px 1px inset;

  &:hover:not([disabled]) {
    color: white;
    background-color: rgb(23, 57, 109);
  }
}

.Success {
  background-color: $success-color;

  &:hover:not([disabled]) {
    background-color: rgba(25, 135, 84, 0.9);
  }
}

.Danger {
  background-color: $danger-color;

  &:hover:not([disabled]) {
    background-color: rgba(220, 53, 70, 0.9);
  }
}

.Warning {
  background-color: $warning-color;

  &:hover:not([disabled]) {
    background-color: rgba(255, 193, 7, 0.9);
  }
}

.danger {
  color: $danger-color !important;
}

.success {
  color: $success-color !important;
}

.Small {
  span {
    font-size: 14px;
  }

  padding: 8px 22px;
}
.XSmall {
  span {
    font-size: 12px;
  }

  padding: 5px 18px;
}

.Medium {
  span {
    font-size: 14px;
  }

  padding: 12px 20px;
}

.Large {
  span {
    font-size: 16px;
  }

  padding: 13px 24px;
}
