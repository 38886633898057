.ping {
    height: 50px;
    width: 50px;

    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 9;
    transform: translate(-50%, -50%);
}

.ping::before,
.ping::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: #17396D;
    animation: pulse7132 1s linear infinite;
    transform: scale(0);
    opacity: 0;
}

.ping::after {
    animation-delay: calc(1s / -2);
}

@keyframes pulse7132 {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}