.modal-wrapper {
    backdrop-filter: blur(2px);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    transition: 350ms all ease;
    z-index: 20;

    div {
        border-radius: 4px;
    }
}

.modal-wrapper > div {
    background-color: #ffffff;
}

.modal-wrapper > div.sm {
    width: 500px;
}

.modal-wrapper > div.lg {
    width: 800px;
}

@media (min-width: 576px) {
    .modal-wrapper > div.sm {
        width: 570px;
    }
}
