a {
  font-size: 14px;
  text-decoration: none;
  transition: 350ms all ease;
  color: #2b2b2b;

  &:hover {
    color: $primary-color;
  }
}

ul {
  li {
    list-style: none;
  }
}

small {
  font-size: 12px;
  color: #434343;
}

.divide {
  border-top: dashed 1px #afafaf;
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root.Mui-selected {
  background-color: #2256a7 !important;
  color: #fff;
}

.menu-drop-down {
  min-width: 135px !important;
}
